import { AUTH_ROUTE, PrimaryButton, SIGNUP_ROUTE, featureFlags } from 'shared';
import { VideoTitle } from 'features/change-video-title';
import { useState } from 'react';
import { GenerateVideo } from 'features/generate-video';
import { creditStore } from 'entities/credit';
import { ReactComponent as CoinsIcon } from './img/coins.svg';
import { BackButton } from 'features/back-button';
import { appStore } from 'app/store';
import { Link, Navigate } from 'react-router-dom';
import { FiUser } from 'react-icons/fi';
import CreateAccModal from 'features/create-acc-modal/ui/CreateAccModal';
import { Credits } from 'features/credits';

export const TopBar = () => {
  const { user } = appStore();

  return (
    <div className="yep_ex-flex yep_ex-h-full yep_ex-justify-between yep_ex-items-center yep_ex-px-4 lg:yep_ex-px-10 yep_ex-text-sm yep_ex-font-medium lg:yep_ex-mb-6">
      <div className="yep_ex-flex yep_ex-items-center yep_ex-gap-x-2">
        <BackButton />
        <div className="yep_ex-h-10 yep_ex-border-l yep_ex-border-neutral-200 yep_ex-mx-2" />
        <VideoTitle />
        {/* // TODO: Add undo/redo functionality
        <div className="flex items-center gap-x-8 text-2xl text-neutral-400">
          <BiUndo />
          <BiRedo />
        </div> */}
      </div>
      <div className="yep_ex-flex yep_ex-flex-row yep_ex-items-center yep_ex-gap-2 md:yep_ex-gap-4">
        {user ? (
          <Credits />
        ) : (
          <div className="yep_ex-flex yep_ex-flex-row yep_ex-gap-1">
            <a
              className="yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-front-semibold yep_ex-bg-white yep_ex-text-neutral-700 yep_ex-rounded-lg yep_ex-px-2.5 yep_ex-py-2 yep_ex-text-sm"
              href={AUTH_ROUTE}
            >
              <div>Login</div>
            </a>
            <a
              className="yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-border yep_ex-border-neutral-200 yep_ex-front-semibold yep_ex-bg-white yep_ex-text-neutral-700 yep_ex-rounded-lg yep_ex-px-2.5 yep_ex-py-2 yep_ex-text-sm"
              href={SIGNUP_ROUTE}
            >
              <div>Sign up</div>
            </a>
          </div>
        )}
        <GenerateVideo />
      </div>
    </div>
  );
};
