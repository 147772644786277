import { PrimaryButton, Divider, Modal, featureFlags } from 'shared';
import { AvatarsList, avatarStore } from 'entities/avatar';
import { AvatarFilters } from 'features/filter-avatars';
import { SwitchBodyMotion } from 'features/switch-body-motion';
import { ReactComponent as Plus } from './svg/plus.svg';
import { creditStore } from 'entities/credit';
import { appStore } from 'app/store';

export const Avatars = () => {
  const {
    filteredTalkingPhotos,
    filteredPublicAvatars,
    filteredCustomTalkingPhotos,
    typeFilters,
    showCustomPhotoModal,
    setShowCustomPhotoModal,
    toggleUploadAvatar
  } = avatarStore();

  const { user, toggleCreateAccModal } = appStore();

  const { planFeatures, toggleFeatureBlockModal } = creditStore();

  const handleUploadPhoto = () => {
    if (!user) return toggleCreateAccModal();

    if (planFeatures?.expressAllowed) {
      toggleUploadAvatar();
    } else {
      toggleFeatureBlockModal('createAvatar');
    }
  };

  return (
    <>
      <div className="yep_ex-px-4 yep_ex-hidden md:yep_ex-block yep_ex-z-10">
        <PrimaryButton
          className="yep_ex-w-full yep_ex-py-2.5 yep_ex-font-semibold"
          isSecondary
          onClick={handleUploadPhoto}
        >
          Upload talking photo
        </PrimaryButton>
        <Divider className="yep_ex-my-6" />
        <AvatarFilters />
      </div>
      <div className="yep_ex-px-4 yep_ex-py-2 yep_ex-flex yep_ex-justify-between md:yep_ex-hidden">
        <AvatarFilters />

        <PrimaryButton
          className="yep_ex-flex yep_ex-gap-2 yep_ex-bg-transparent yep_ex-border-transparent yep_ex-items-center yep_ex-whitespace-nowrap hover:yep_ex-bg-transparent hover:yep_ex-border-transparent"
          onClick={handleUploadPhoto}
          isSecondary
        >
          <Plus />
          Upload talking photo
        </PrimaryButton>
      </div>
      <Divider className="yep_ex-mb-4 md:yep_ex-hidden" />
      {featureFlags.dynamicAvatars && <SwitchBodyMotion />}
      <div className="yep_ex-px-4 yep_ex-overflow-auto yep_ex-no-scrollbar yep_ex-w-full md:yep_ex-z-0">
        {(!typeFilters.length || typeFilters.includes('custom')) && (
          <AvatarsList
            avatars={filteredCustomTalkingPhotos}
            title="Custom Photos"
          />
        )}

        {(!typeFilters.length || typeFilters.includes('talkingPhoto')) && (
          <AvatarsList avatars={filteredTalkingPhotos} title="Talking Photos" />
        )}

        {(!typeFilters.length || typeFilters.includes('avatar')) && (
          <AvatarsList avatars={filteredPublicAvatars} title="Avatars" />
        )}
      </div>
    </>
  );
};
